import { AppBar, Grid, Toolbar } from "@mui/material";
import React from "react";
import { Route, Routes as Switch } from "react-router-dom";

const DyPatil = React.lazy(() => import("./Pages/DyPatil"));
const Privacy = React.lazy(() => import("./Pages/Common/Privacy"));
const DistanceLearning = React.lazy(() => import("./Pages/DistanceLearning"));
const Home = React.lazy(() => import("./Pages/Home"));
const CollegeTour = React.lazy(() => import("./Pages/CollegeTour"));

// const Login = React.lazy(() => import("./Pages/EarlySalary/Login"));
// const EarlySalaryData = React.lazy(() =>
//   import("./Pages/EarlySalary/EarlySalaryData")
// );

const Routes = (props) => {
  return (
    <>
      <AppBar
        sx={(theme) => ({
          position: "fixed",
          height: "70px",
          backgroundColor: "#151515",
          [theme.breakpoints.down("md")]: {
            height: "50px",
          },
        })}
      >
        <Toolbar
          sx={(theme) => ({
            paddingRight: "10%",
            [theme.breakpoints.down("md")]: {
              height: "50px",
            },
          })}
        >
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <Grid
              sx={(theme) => ({
                width: "8vw",
                [theme.breakpoints.down("md")]: {
                  width: "20vw",
                },
              })}
            >
              <img
                alt="logo"
                src="/images/logo.svg"
                style={{
                  width: "100%",
                }}
              />
            </Grid>
          </div>
          <div>
            <Grid
              sx={(theme) => ({
                width: "10vw",
                [theme.breakpoints.down("md")]: {
                  width: "20vw",
                },
              })}
            >
              {/* <img
                alt="logo"
                src="/images/logoright.svg"
                style={{
                  width: "100%",
                }}
              /> */}
            </Grid>
          </div>
        </Toolbar>
      </AppBar>
      <Switch>
        {/* <Route path={"/earlysalary/login"} element={<Login />} />
      <Route path={"/earlysalary/data"} element={<EarlySalaryData />} />

      <Route path={"/earlysalary"} element={<EarlySalary />} /> */}
        <Route path={"/"} element={<Home />} />
        <Route path={"/thankyou"} element={<Home />} />
        <Route path={"/distance"} element={<DistanceLearning />} />
        <Route path={"/onlinemba"} element={<CollegeTour />} />
        <Route path={"/onlinembathankyou"} element={<CollegeTour />} />

        <Route path={"/lp1"} element={<DyPatil />} />
        <Route path={"/lp1/thankyou"} element={<DyPatil />} />

        <Route path={"/thankyou"} element={<Home />} />
        <Route path={"/privacy"} element={<Privacy />} />
      </Switch>
    </>
  );
};

export default Routes;
