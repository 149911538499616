import { BrowserRouter as Router } from "react-router-dom";
import React from "react";
import Routes from "./appRoutes";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

import QuoraPixels from "./Pages/Common/quroPixels";
import { gtag, install } from "ga-gtag";

install("AW-646099598", { send_page_view: true });

QuoraPixels.init("f077cb7789e3411da4295b84460427c8");
QuoraPixels.track("ViewContent");

// TagManager.initialize({ gtmId: "AW-646099598" });

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Router>
        <Routes />
      </Router>
    </LocalizationProvider>
  );
}

export default App;
